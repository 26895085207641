<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex mt-4 flex-col">
        <div class="flex items-center justify-end space-x-2">
          <input
            :value="filter.gudang.code"
            type="text"
            name="search"
            disabled
            id="search"
            class="rounded-md border-gray-300 bg-gray-100 pl-4 focus:border-green-500 focus:ring-green-500 sm:text-sm"
            placeholder="Cari kode atau nama"
          />
          <base-search
            v-model="search"
            @input="debounceSearch"
            placeholder="Cari kode atau nama"
          />
          <export-dropdown />
        </div>
        <div class="flex my-2 sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getStocks.meta.page.total"
              :perPage="getStocks.meta.page.perPage"
              :currentPage="getStocks.meta.page.currentPage"
              @pagechanged="onPageChangeProducts"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Barang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Barang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Stock
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Stock ORDERED
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Gudang
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <div
                  v-if="isStockLoading || isWarehouseLoading"
                  class="flex items-center text-center"
                >
                  <loading></loading>
                </div>
                <tbody v-if="getStocks.data.length">
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getStocks.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click="detailStock(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ data.attributes.product_code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.product_name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.stock_qty }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.stock_ordered }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.warehouse_code }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="bg-white">
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      colspan="8"
                    >
                      Data tidak ditemukan
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
        <div
          v-if="openDetail"
          class="fixed inset-0 z-10 mx-4 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
            >
              <div class="flex justify-between px-4 py-5 sm:px-6">
                <div>
                  <h3 class="text-lg font-bold leading-6 text-gray-900">
                    Detail Stock
                  </h3>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    Berdasarkan data stock yang telah dibuat
                  </p>
                </div>
                <div>
                  <img src="@/assets/images/logo.png" alt="" class="w-52" />
                </div>
              </div>
              <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl
                  class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6 sm:pb-6"
                >
                  <div class="sm:col-span-2">
                    <dt class="text-sm font-bold text-gray-700">Kode Barang</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataStock.attributes.product_code }}
                    </dd>
                  </div>
                  <div class="sm:col-span-2">
                    <dt class="text-sm font-bold text-gray-700">Nama Barang</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataStock.attributes.product_name }}
                    </dd>
                  </div>
                  <div class="sm:col-span-2">
                    <dt class="text-sm font-bold text-gray-700">Stock</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataStock.attributes.stock_qty }}
                    </dd>
                  </div>
                  <div class="sm:col-span-2">
                    <dt class="text-sm font-bold text-gray-700">
                      Status Order
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <span
                        v-if="dataStock.attributes.is_orderable"
                        class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                      >
                        Open
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
                      >
                        Close
                      </span>
                    </dd>
                  </div>
                  <div class="sm:col-span-2">
                    <dt class="text-sm font-bold text-gray-700">
                      Status Pre Order
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <span
                        v-if="dataStock.attributes.is_pre_orderable"
                        class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                      >
                        Open
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
                      >
                        Close
                      </span>
                    </dd>
                  </div>
                  <div class="sm:col-span-2">
                    <dt class="text-sm font-bold text-gray-700">Status Izin</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <span
                        v-if="dataStock.attributes.license_status"
                        class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                      >
                        Sudah Berizin
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
                      >
                        Belum Berizin
                      </span>
                    </dd>
                  </div>
                  <div class="sm:col-span-2">
                    <dt class="text-sm font-bold text-gray-700">Total Berat</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataStock.attributes.total_weight }}
                    </dd>
                  </div>
                </dl>
              </div>
              <div class="flex justify-end">
                <button
                  @click="openDetail = !openDetail"
                  type="button"
                  class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>

        <transition
          name="customFade"
          enter-active-class="fadeIn"
          leave-active-class="fadeOut"
        >
          <div
            v-if="openListWarehouse"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
              >
                <div class="flex justify-between px-4 py-5 sm:px-6">
                  <div>
                    <h3 class="text-lg font-bold leading-6 text-gray-900">
                      Daftar Gudang
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                      Berdasarkan data gudang yang telah dibuat
                    </p>
                  </div>
                  <div>
                    <img src="@/assets/images/logo.png" alt="" class="w-52" />
                  </div>
                </div>
                <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div class="flex justify-end">
                    <base-search
                      v-model="searchWarehouse"
                      @input="debounceSearchWarehouse"
                      placeholder="Cari kode atau nama"
                    />
                  </div>
                  <div class="flex my-2 sm:-mx-6 lg:-mx-8">
                    <div
                      class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
                    >
                      <Datatable
                        :total="getWarehouses.meta.page.total"
                        :perPage="getWarehouses.meta.page.perPage"
                        :currentPage="getWarehouses.meta.page.currentPage"
                        @pagechanged="onPageChangeWarehouse"
                      >
                        <template v-slot:thead>
                          <thead class="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Kode Gudang
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Nama Gudang
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Kode Area
                              </th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:tbody>
                          <tbody>
                            <tr
                              class="cursor-pointer bg-white hover:bg-green-100"
                              v-for="(data, dataIdx) in getWarehouses.data"
                              :key="data.id"
                              :class="
                                dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                              "
                              @click="changeWarehouse(data)"
                            >
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                              >
                                {{ data.attributes.code }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {{ data.attributes.name }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {{ data.attributes.area_code }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </Datatable>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end">
                  <button
                    @click="openListWarehouse = !openListWarehouse"
                    type="button"
                    class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import ExportDropdown from '@/components/stock/ExportDropdown.vue';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Barang',
  components: {
    Datatable,
    BaseSearch,
    ExportDropdown,
  },
  data() {
    return {
      search: null,
      searchWarehouse: '',
      openDetail: false,
      openListWarehouse: false,
      dropdown: false,
      dataStock: {},
      filter: {
        gudang: {
          id: '',
          code: '',
          name: '',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isStockLoading: 'stocks/getLoading',
      isWarehouseLoading: 'warehouses/getLoading',
      getStocks: 'stocks/getStocks',
      getWarehouseByOffice: 'warehouses/getWarehouseByOffice',
      getWarehouses: 'warehouses/getWarehouses',
      getWarehouseStock: 'warehouses/getWarehouseStock',
      me: 'auth/getUser',
    }),

    filteredData() {
      return this.getResults.filter((products) =>
        products.attributes.name
          .toLowerCase()
          .includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions({
      fetchStocksByWarehouse: 'stocks/fetchStocksByWarehouse',
      fetchWarehouseByOffice: 'warehouses/fetchWarehouseByOffice',
      fetchWarehouses: 'warehouses/fetchWarehouses',
      fetchWarehouseById: 'warehouses/fetchWarehouseById',
      fetchWarehouseStock: 'warehouses/fetchWarehouseStock',
    }),
    openModal() {
      this.openListWarehouse = !this.openListWarehouse;
      this.fetchWarehouses({
        pageNumber: 1,
        pageSize: 5,
      });
    },
    getProductCategory(id) {
      let category = this.getProducts.included.filter(function (el) {
        return el.id === id;
      });
      return category[0].attributes.name;
    },
    onPageChangeProducts(page) {
      this.fetchStocksByWarehouse({
        warehouse_id: this.filter.gudang.id,
        pageNumber: page,
        pageSize: 5,
        search: this.search,
      });
    },
    onPageChangeWarehouse(page) {
      this.fetchWarehouses({
        pageNumber: page,
        pageSize: 5,
        search: this.searchWarehouse,
      });
    },
    debounceSearch: debounce(function () {
      this.fetchStocksByWarehouse({
        warehouse_id: this.filter.gudang.id,
        pageNumber: 1,
        pageSize: 5,
        search: this.search,
      });
    }, 300),
    debounceSearchWarehouse: debounce(function () {
      this.fetchWarehouses({
        pageNumber: 1,
        pageSize: 5,
        search: this.searchWarehouse,
      });
    }, 300),
    detailStock(stock) {
      this.dataStock = stock;
      this.openDetail = !this.openDetail;
    },
    changeWarehouse(data) {
      this.filter.gudang.id = data.id;
      this.filter.gudang.code = data.attributes.code;
      this.filter.gudang.name = data.attributes.name;
      this.fetchStocksByWarehouse({
        warehouse_id: this.filter.gudang.id,
        pageNumber: 1,
        pageSize: 5,
      });
      this.openListWarehouse = !this.openListWarehouse;
      this.searchWarehouse = '';
    },
  },
  created() {
    this.fetchWarehouseById({
      id: this.me.current_warehouse,
    }).then((response) => {
      if (response) {
        this.filter.gudang.id = response.data.data.id;
        this.filter.gudang.code = response.data.data.attributes.code;
        this.filter.gudang.name = response.data.data.attributes.name;
        this.fetchStocksByWarehouse({
          warehouse_id: this.filter.gudang.id,
          pageNumber: 1,
          pageSize: 5,
        });
      }
    });
  },
};
</script>
