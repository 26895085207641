<template>
  <div class="">
    <div class="mx-4 space-y-6 pt-2 pb-4 lg:mx-8">
      <!-- Tabel Penjualan -->
      <OrderBundlesCheckboxTable
        @page-change="onPageChangeOrders"
        @change="(val) => (checkedOrders = val)"
      />

      <!-- Detail Laporan -->
      <BaseCard>
        <base-input
          :value="grandTotal | toCurrency"
          type="text"
          inset
          label="Total Yang Dibayarkan"
          name="total_dibayarkan"
          id="total_dibayarkan"
          placeholder="ex: Laporan Penjualan"
          disabled
        />
      </BaseCard>

      <!-- Form Laporan -->
      <div class="space-y-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
        <div class="flex w-full flex-row gap-x-4 gap-y-2">
          <div class="w-full">
            <div class="field-inset-default relative">
              <label
                for="nama_laporan"
                class="block text-xs font-bold text-gray-700"
              >
                Nama Laporan
              </label>
              <input
                v-model="report.name"
                type="text"
                name="nama_laporan"
                id="nama_laporan"
                class="input-inset-select"
                placeholder="ex: Laporan Penjualan"
              />
            </div>
          </div>
          <div class="relative w-full">
            <div class="field-inset-default relative">
              <label
                for="kode_gudang"
                class="block text-xs font-bold text-gray-700"
              >
                Deskripsi Laporan
              </label>
              <input
                v-model="report.description"
                type="text"
                name="saldo_awal"
                id="saldo_awal"
                class="input-inset-select"
                placeholder="ex: beli 5 barang dibayar lunas"
              />
            </div>
          </div>
        </div>

        <PaymentMethodInlineForm v-model="payments" />
      </div>

      <div class="flex justify-end">
        <base-button
          :disabled="!(checkedOrders.length > 0 && totalPayment >= grandTotal)"
          @click="sendReport"
          type="button"
        >
          Simpan Laporan
        </base-button>
      </div>
    </div>

    <!-- Modal Succes -->

    <transition name="fade">
      <div
        v-if="success"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal
          @modal-action="successModal"
          link="/kasir/laporan-kasir-kp"
        >
          <template v-slot:svg>
            <div
              class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                class="h-6 w-6 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              Data berhasil disimpan
            </h3>
          </template>
        </modal>
      </div>
    </transition>

    <!-- end of modal success -->

    <!-- Modal error -->
    <transition name="fade">
      <div
        v-if="failed"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal
          @modal-action="failedModal"
          link="/kasir/laporan-kasir-kp"
        >
          <template v-slot:svg>
            <div
              class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-red-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              {{ getError }}
            </h3>
          </template>
        </modal>
      </div>
    </transition>
    <!-- end of modal error -->

    <!-- Loader -->
    <loading v-if="isLoading"></loading>
  </div>
</template>
<script>
import Modal from '@/components/Modal.vue';
import PaymentMethodInlineForm from '@/components/payment/PaymentMethodInlineForm.vue';
import { paymentMethodFormMixin } from '@/mixins/payment-method/payment-method-form.mixin';
import { orderCreateMixin } from '@/mixins/order/order-create.mixin';
import OrderBundlesCheckboxTable from '@/components/order-bundles/OrderBundlesCheckboxTable.vue';
import { orderBundleCreateMixins } from '@/mixins/order-bundle/order-bundle-create.mixin';

export default {
  name: 'TambahPenjualan',
  mixins: [paymentMethodFormMixin, orderCreateMixin, orderBundleCreateMixins],
  components: {
    Modal,
    PaymentMethodInlineForm,
    OrderBundlesCheckboxTable,
  },
  created() {
    this.orders.filter.origin_warehouse_id = this.getUser.current_warehouse;
    this.loadOrders();
    this.setupPaymentMethodTypes({ officeId: this.getUser.office_id });
    this.loadOriginOffice();
    this.loadPusatOfficeBanks();
  },
};
</script>
