<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <Tabs
          :tabs-data="tabs"
          :tab-active="currentTab"
          @updateTabActive="changeTab"
        >
          <template v-slot:default="{ tabActive }">
            <template
              v-if="tabActive === 'Umum' && getWarehouse.data"
              class="transition duration-300 ease-in-out"
            >
              <dl class="flex w-full flex-row gap-x-4">
                <div class="w-full">
                  <dt class="text-xs text-gray-400">Kode Gudang</dt>
                  <dd class="mt-1 text-sm font-bold text-gray-800">
                    {{ getWarehouse.data.attributes.code }}
                  </dd>
                </div>
                <div class="w-full">
                  <dt class="text-xs text-gray-400">Nama Gudang</dt>
                  <dd class="mt-1 text-sm font-bold text-gray-800">
                    {{ getWarehouse.data.attributes.name }}
                  </dd>
                </div>
                <div class="w-full">
                  <dt class="text-xs text-gray-400">Kode Area</dt>
                  <dd class="mt-1 text-sm font-bold text-gray-800">
                    {{ getWarehouse.data.attributes.area_code }}
                  </dd>
                </div>
              </dl>
            </template>
            <template
              v-else-if="tabActive === 'Ekspedisi'"
              class="transition duration-300 ease-in-out"
            >
              <setup-ekspedisi />
            </template>
          </template>
        </Tabs>
      </div>
    </div>

    <transition name="fade">
      <div
        v-if="success"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal @modal-action="closeModal" link="/gudang">
          <template v-slot:svg>
            <div
              class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                class="h-6 w-6 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              Data berhasil disimpan
            </h3>
          </template>
        </modal>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="failed"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal @modal-action="closeModal" link="/gudang">
          <template v-slot:svg>
            <div
              class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-red-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              Gagal memperbarui ekspedisi
            </h3>
          </template>
        </modal>
      </div>
    </transition>
    <template v-if="modal">
      <ExpeditionModal
        @closeModal="closeModal"
        :modal="modal"
        :formData="formData"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { StorageService } from '@/services/storage.service';
import Modal from '@/components/Modal.vue';
import Tabs from '@/components/Tabs.vue';
import ExpeditionModal from '@/components/ExpeditionModal.vue';
import SetupEkspedisi from '@/components/setup/setup-ekspedisi.vue';

export default {
  name: 'Setup',
  components: {
    Modal,
    ExpeditionModal,
    Tabs,
    SetupEkspedisi,
  },
  data() {
    return {
      tabs: ['Umum', 'Ekspedisi'],
      currentTab: 'Umum',
      expeditions: [],
      success: false,
      failed: false,
      modal: '',
      formData: null,
    };
  },
  created() {
    this.fetchProvinces();
    this.currentTab = this.$route.query.tab
      ? this.tabs.includes(this.$route.query.tab)
        ? this.$route.query.tab
        : 'Umum'
      : 'Umum';
    this.fetchWarehouseById({
      id: StorageService.getUser().current_warehouse,
    });
    this.fetchOfficeExpeditionsByOffice({
      office_id: StorageService.getUser().office_id,
    }).then((response) => {
      response.data.data.map((expedition) => {
        this.expeditions.push(expedition.id);
      });
    });
  },
  computed: {
    ...mapGetters({
      getWarehouse: 'warehouses/getWarehouse',
      getExpeditions: 'expeditions/getExpeditions',
      getOfficeExpeditions: 'office_expeditions/getOfficeExpeditions',
    }),
  },
  methods: {
    ...mapActions({
      fetchProvinces: 'indonesia/fetchProvinces',
      fetchWarehouseById: 'warehouses/fetchWarehouseById',
      fetchExpeditions: 'expeditions/fetchExpeditions',
      fetchOfficeExpeditions: 'office_expeditions/fetchOfficeExpeditions',
      fetchOfficeExpeditionsByOffice:
        'office_expeditions/fetchOfficeExpeditionsByOffice',
      addExpeditionToOffice: 'offices/addExpeditionToOffice',
      updateOfficeExpedition: 'office_expeditions/updateOfficeExpedition',
    }),
    changeActive(expedition) {
      const payload = {
        data: {
          id: expedition.id,
          type: 'office-expeditions',
          attributes: {
            isActive: !expedition.attributes.isActive,
          },
        },
      };
      this.updateOfficeExpedition(payload).then(() => {
        this.fetchOfficeExpeditionsByOffice({
          office_id: StorageService.getUser().office_id,
        });
      });
    },
    editExpedition(data) {
      this.modal = 'add';
      this.formData = data;
    },
    removeExpedition(data) {
      const payload = {
        data: {
          type: 'office-expeditions',
          id: data.id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      };
      this.updateOfficeExpedition(payload).then((response) => {
        if (response) {
          this.fetchOfficeExpeditionsByOffice({
            office_id: StorageService.getUser().office_id,
          }).then((response) => {
            response.data.data.map((expedition) => {
              this.expeditions.push(expedition.id);
            });
          });
        } else {
          this.failed = true;
        }
      });
    },
    changeTab(tab) {
      this.currentTab = tab;
    },
    getDataDetails(params) {
      let dataDetail = [];
      params.map((param) => {
        let data = {
          type: 'office-expeditions',
          id: param,
        };
        dataDetail.push(data);
      });
      return dataDetail;
    },
    saveExpedition() {
      const payload = {
        data: this.getDataDetails(this.expeditions),
      };
      this.addExpeditionToOffice({
        office_id: StorageService.getUser().office_id,
        payload,
      }).then((response) => {
        if (response) {
          this.success = true;
        } else {
          this.failed = false;
        }
      });
    },
    openAddExpedition() {
      this.modal = 'add';
    },
    closeModal() {
      this.success = this.failed = false;
      this.modal = this.formData = null;
      this.fetchOfficeExpeditionsByOffice({
        office_id: StorageService.getUser().office_id,
      }).then((response) => {
        response.data.data.map((expedition) => {
          this.expeditions.push(expedition.id);
        });
      });
    },
  },
};
</script>
