<template>
  <div class="m-2 rounded-2xl">
    <div class="py-2 sm:px-6">
      <section>
        <div class="flex flex-col">
          <div class="flex justify-end">
            <base-search
              v-model="search"
              @input="debounceSearch"
              placeholder="Cari kode atau nama"
            />
          </div>
          <div class="my-2 -flex sm:-mx-6 lg:-mx-8">
            <div
              class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
            >
              <Datatable
                :total="getOrders.meta.page.total"
                :perPage="getOrders.meta.page.perPage"
                :currentPage="getOrders.meta.page.currentPage"
                @pagechanged="onPageChangeOrder"
              >
                <template v-slot:thead>
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nomor Faktur KP
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nomor Restock
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Tanggal Faktur KP
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Total Produk
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Total Berat
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Proses
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        STATUS
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:tbody>
                  <div v-if="isLoading" class="flex items-center text-center">
                    <loading></loading>
                  </div>
                  <tbody v-if="getOrders.data.length">
                    <tr
                      class="cursor-pointer bg-white hover:bg-green-100"
                      v-for="(data, dataIdx) in getOrders.data"
                      :key="data.id"
                      :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                      @click.prevent="changeSale(data)"
                    >
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{ data.attributes.origin_code }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ data.attributes.destination_code }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ dayjs(data.attributes.updatedAt).format('ll LT') }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                      >
                        {{ data.attributes.item_count }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                      >
                        {{ data.attributes.grand_total_weight }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                      >
                        <span
                          class="inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium"
                          :class="[
                            data.attributes.order_status === 'Diproses Gudang'
                              ? 'status-draft'
                              : '',
                            data.attributes.order_status === 'Dikirim'
                              ? 'status-ongoing'
                              : '',
                            data.attributes.order_status === 'Selesai'
                              ? 'status-done'
                              : '',
                          ]"
                        >
                          {{ data.attributes.order_status }}
                        </span>
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                      >
                        <span
                          v-if="data.attributes.is_valid_for_payment"
                          class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                        >
                          Selesai
                        </span>
                        <span
                          v-else
                          class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                        >
                          Belum Selesai
                        </span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr class="bg-white">
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                        colspan="8"
                      >
                        Data tidak ditemukan
                      </td>
                    </tr>
                  </tbody>
                </template>
              </Datatable>
            </div>
          </div>
        </div>
      </section>

      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="openDetail"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
            >
              <div class="flex mb-8 justify-between">
                <div>
                  <h3 class="text-lg font-bold leading-6 text-gray-900">
                    Detail Faktur KP
                  </h3>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    Detail Faktur KP yang dibuat di Pusat
                  </p>
                </div>
                <div>
                  <img
                    src="@/assets/images/logo.png"
                    alt=""
                    class="flex justify-end"
                  />
                </div>
              </div>
              <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl class="flex flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      No Faktur KP
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataPenjualan.attributes.origin_code }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Tanggal</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{
                        dayjs(dataPenjualan.attributes.updatedAt).format(
                          'll LT'
                        )
                      }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Kode KP</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{
                        dataPenjualan.relationships['destination-office'].data
                          ? getRelationships(
                              dataPenjualan.relationships['destination-office']
                                .data.id
                            ).attributes.code
                          : '-'
                      }}
                    </dd>
                  </div>
                </dl>
                <dl class="flex mt-6 flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Nama KP</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{
                        dataPenjualan.relationships['destination-office'].data
                          ? getRelationships(
                              dataPenjualan.relationships['destination-office']
                                .data.id
                            ).attributes.name
                          : '-'
                      }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Alamat KP 1</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{
                        dataPenjualan.relationships['destination-office'].data
                          ? getRelationships(
                              dataPenjualan.relationships['destination-office']
                                .data.id
                            ).attributes.address
                          : '-'
                      }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Alamat KP 2</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{
                        dataPenjualan.relationships['destination-office'].data
                          ? getRelationships(
                              dataPenjualan.relationships['destination-office']
                                .data.id
                            ).attributes.address2
                          : '-'
                      }}
                    </dd>
                  </div>
                </dl>
                <div class="my-6 sm:col-span-6">
                  <dt class="text-sm font-bold text-gray-700">Daftar Barang</dt>
                  <Datatable :paginated="false" :footer="false" class="mt-4">
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            KODE BARANG
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            JUMLAH
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            BERAT
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            TOTAL BERAT
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <tr
                          class="bg-white"
                          v-for="(barang, index) in dataPenjualan.attributes
                            .order_details"
                          :key="index"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{ barang.product_code }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-900"
                          >
                            {{ barang.product_qty | toCurrency }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-900"
                          >
                            {{ barang.product_weight }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-900"
                          >
                            {{ barang.total_weight }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </Datatable>
                </div>
                <div class="flex">
                  <div class="flex w-2/3 flex-col gap-y-2">
                    <span
                      v-if="dataPenjualan.is_valid_for_payment"
                      class="inline-flex w-24 items-center justify-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                    >
                      Selesai
                    </span>
                    <span
                      v-else
                      class="inline-flex w-36 items-center justify-center rounded-full bg-red-100 px-3 py-1 text-center text-sm font-medium text-red-800"
                    >
                      Belum Selesai
                    </span>
                  </div>
                  <div class="flex w-1/3 flex-col gap-y-2">
                    <div class="inline-flex w-full justify-between">
                      <div class="sm:col-span-1">
                        <label
                          for="invoice"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Total harga keseluruhan
                        </label>
                      </div>
                      <div class="sm:col-span-1">
                        <label
                          for="invoice"
                          class="block text-sm font-bold text-gray-900"
                        >
                          {{
                            dataPenjualan.attributes.final_price | toCurrency
                          }}
                        </label>
                      </div>
                    </div>
                    <div class="inline-flex w-full justify-between">
                      <div class="sm:col-span-1">
                        <label
                          for="invoice"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Total berat keseluruhan
                        </label>
                      </div>
                      <div class="sm:col-span-1">
                        <label
                          for="invoice"
                          class="block text-sm font-bold text-gray-900"
                        >
                          {{ dataPenjualan.attributes.grand_total_weight }}
                          Kg
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex justify-end">
                <button
                  @click="openDetail = !openDetail"
                  type="button"
                  class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import { StorageService } from '@/services/storage.service';
import BaseSearch from '@/components/base/Search.vue';
import dayjs from 'dayjs';

export default {
  name: 'Home',
  components: {
    Datatable,
    BaseSearch,
  },

  data() {
    return {
      search: null,
      openDetail: false,
      dataPenjualan: {},
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      isLoading: 'orders/getLoading',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
    }),
    getRelationships(id) {
      let data = this.getOrders.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    debounceSearch: debounce(function () {
      this.fetchOrders({
        // order_type: 'sale',
        pageNumber: 1,
        pageSize: 5,
        search: this.search,
        destination_warehouse_id: StorageService.getUser().current_warehouse,
      });
    }, 300),
    onPageChangeOrder(page) {
      this.fetchOrders({
        // order_type: 'sale',
        pageNumber: page,
        pageSize: 5,
        search: this.search,
        destination_warehouse_id: StorageService.getUser().current_warehouse,
      });
    },
    changeSale(data) {
      this.openDetail = !this.openDetail;
      this.dataPenjualan = data;
    },
  },
  created() {
    this.fetchOrders({
      // order_type: 'sale',
      pageNumber: 1,
      pageSize: 5,
      destination_warehouse_id: StorageService.getUser().current_warehouse,
    });

    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
